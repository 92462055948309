import { Workbox } from "workbox-window";

export default function registerServiceWorker() {

    

    if('serviceWorker' in navigator) {
        const wb = new Workbox('sw.js');

        wb.addEventListener('installed', event => {
            if(event.isUpdate) {
                if(confirm('Nova atualização do aplicativo. Clique Ok para atualizar')) {
                    window.location.reload();
                }
            }
        })

        wb.register();
    }
}