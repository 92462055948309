// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--color-neutral-200);
}

:root {
    --color-purple: #821E82;
    --color-green: #00B3B4;
    --color-primary: #DF0024;
    --color-neutral-100: #F6F6F6;
    --color-neutral-200: #F1F3F5;
    --color-neutral-400: #DEE2E6;
    --color-neutral-700: #6A7178;
    --color-neutral-900: #000000;

    --spacing-16: 16px;
    --spacing-32: 32px;
}

.container {
    display: flex;
    justify-content: center;
    gap: var(--spacing-16);
    height: 80vh;
    margin-top: var(--spacing-32);
}

.container__client {
    width: 253px;
    background-color: #fff;
}

.container__process {
    width: 1131px;
    background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,wBAAwB;IACxB,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;;IAE5B,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nbody {\n    background-color: var(--color-neutral-200);\n}\n\n:root {\n    --color-purple: #821E82;\n    --color-green: #00B3B4;\n    --color-primary: #DF0024;\n    --color-neutral-100: #F6F6F6;\n    --color-neutral-200: #F1F3F5;\n    --color-neutral-400: #DEE2E6;\n    --color-neutral-700: #6A7178;\n    --color-neutral-900: #000000;\n\n    --spacing-16: 16px;\n    --spacing-32: 32px;\n}\n\n.container {\n    display: flex;\n    justify-content: center;\n    gap: var(--spacing-16);\n    height: 80vh;\n    margin-top: var(--spacing-32);\n}\n\n.container__client {\n    width: 253px;\n    background-color: #fff;\n}\n\n.container__process {\n    width: 1131px;\n    background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
