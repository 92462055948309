import { registerApplication, start } from "single-spa";
import './global.css';
import registerServiceWorker from "./serviceWorkerRegistration";

registerApplication({
  name: "@ctt/assistant",
  app: () => System.import("@ctt/assistant"),
  activeWhen: ["/"]
});

registerApplication({
  name: "@ctt/client",
  app: () => System.import("@ctt/client"),
  activeWhen: ["/"]
});

registerApplication({
  name: "@ctt/process",
  app: () => System.import("@ctt/process"),
  activeWhen: ["/"]
});

start({
  urlRerouteOnly: true,
});

registerServiceWorker();
